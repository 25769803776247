/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Preview from './Preview'
import SortPanel from 'src/components/SortPanel'
import { injectIntl } from 'react-intl'
import MissingContent from 'src/components/MissingContent'
import { handleSortByDuration, handleSortByDate } from 'src/utils'
import styles from './VideosList.module.scss'

class VideosList extends Component {
  state = {
    videos: handleSortByDate(
      this.props.videos.map(({ node }) => {
        return { ...node }
      }),
    ),
  }

  handleSort = (ev) => {
    const { videos } = this.state
    let activeOption = ev.target.value
    switch (activeOption) {
      case 'duration':
        this.setState({ videos: handleSortByDuration(videos) })
        break
      case 'date':
        this.setState({ videos: handleSortByDate(videos) })
      default:
    }
  }

  render() {
    const { style, locale, intl } = this.props
    const { videos } = this.state
    const videosLength = videos && videos.length
    const options = [
      { type: 'date', text: intl.formatMessage({ id: 'home.dateAdd' }) },
      { type: 'duration', text: intl.formatMessage({ id: 'home.duration' }) },
    ]

    const content = (
      <>
        <SortPanel
          title={intl.formatMessage({ id: 'home.AllVideos' })}
          options={options}
          sortTitle={intl.formatMessage({ id: 'home.Sortby' })}
          onGetOption={(ev) => this.handleSort(ev)}
        />
        <section className={styles.root} style={style}>
          <div className={styles.inner}>
            <div className={styles.wrap}>
              {videos &&
                videos.map((item, index) => {
                  if (videosLength === 2) {
                    return <Preview small key={item.videoId} {...item} locale={locale} />
                  } else if (videosLength % 2 === 0) {
                    if (index === 0 || index % 3 === 0) {
                      return <Preview key={item.videoId} {...item} locale={locale} />
                    } else {
                      return <Preview small key={item.videoId} {...item} locale={locale} />
                    }
                  } else {
                    if (index === 0 || (index % 3 === 0 && index !== videosLength - 2)) {
                      return <Preview key={item.videoId} {...item} locale={locale} />
                    } else if (index === videosLength - 1 || index === videosLength - 2) {
                      return <Preview small key={item.videoId} {...item} locale={locale} />
                    } else {
                      return <Preview small key={item.videoId} {...item} locale={locale} />
                    }
                  }
                })}
            </div>
          </div>
        </section>
      </>
    )
    return videos ? content : <MissingContent locale={locale} title={intl.formatMessage({ id: 'home.missingVideo' })} />
  }
}

VideosList.propTypes = {
  videos: PropTypes.array.isRequired,
  locale: PropTypes.string,
}

export default injectIntl(VideosList)
