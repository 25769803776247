import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import VideosList from '../components/VideosList'
import Subscribe from '../components/Subscribe'
import Footer from '../components/Footer'
import PageHelmet from 'src/components/PageHelmet'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class Videos extends Component {
  render() {
    const {
      data: {
        // channels,
        video: { edges },
        subscribe,
      },
      locale,
      changeLocale,
      intl,
    } = this.props

    const videos = edges.filter(({ node }) => node.title)

    return (
      <div>
        <PageHelmet title={intl.formatMessage({ id: 'home.videosButton' })} locale={locale} defer={false} />
        <Header full={true} {...this.props} locale={locale} changeLocale={changeLocale} />
        <VideosList videos={videos} locale={locale} />
        <Subscribe style={{ marginTop: '15px' }} locale={locale} subscribe={subscribe} />
        <Footer locale={locale} />
      </div>
    )
  }
}

export const query = graphql`
  query Videos($locale: String!) {
    video: allDatoCmsVideo2(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          channel {
            title
          }
          title
          videoId
          description
          duration
          publishDate
          slug
        }
      }
    }

    channels: allDatoCmsVideoChannel(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }

    subscribe: file(relativePath: { eq: "subscribe-illustration.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'videos',
}

export default withLayout(customProps)(injectIntl(Videos))
